import React, { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import { IAirport } from '../types';
import { Input, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import usePrefix from '@/hooks/usePrefix';
import Link from 'next/link';
import { SearchOutlined, UpOutlined } from '@ant-design/icons';

export interface IAirportsTable {
  airports: { result: IAirport[] };
  isCityPage?: boolean;
  closeAirports?: () => void;
}

const AirportsTable = ({
  isCityPage = false,
  airports,
  closeAirports,
}: IAirportsTable): ReactElement => {
  const prefix = usePrefix();
  const [searchText, setSearchText] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);

  const columns: ColumnsType<IAirport> = [
    {
      title: 'Airport Name',
      dataIndex: 'airport_name',
      key: 'airport_name',
    },
    {
      title: 'Airport Code',
      dataIndex: 'airport_initials',
      key: 'airport_initials',
    },
    !isCityPage && {
      title: 'Booking Park',
      dataIndex: 'reserve_now',
      key: 'reserve_now',
      render: (text, record) => (
        <Link
          href={`${prefix}/parking/${record.airport_frienldy_url}`}
          className="text-primary hover:underline hover:text-primary"
          title={record.airport_name}
        >
          Reserve Now
        </Link>
      ),
    },
  ].filter(Boolean);

  const filteredData = airports?.result.filter(
    (item) =>
      item['airport_name'].toLowerCase().includes(searchText.toLowerCase()) ||
      item['airport_initials'].toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Input
        placeholder="Airport Name or Code"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        className="w-full md:w-auto mb-4 md:float-right"
        suffix={<SearchOutlined />}
      />
      <Table
        pagination={false}
        dataSource={searchText ? filteredData : airports?.result}
        columns={columns}
      />

      {showScrollButton && (
        <p
          className="fixed bottom-5 right-5 z-10 bg-primary rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (closeAirports) {
              closeAirports();
            }
          }}
        >
          <UpOutlined className="text-lg" />
        </p>
      )}
    </div>
  );
};

export default AirportsTable;
